export default defineNuxtRouteMiddleware(async (to) => {
  const { $portalApi, $i18n } = useNuxtApp()

  if ('app_id' in to.params && to.name === 'apps-app_id-credentials') {
  // prevents navigation to 'credentials' page for the application with OIDC auth strategy
    try {
      const data = await $portalApi('/api/v3/applications/{applicationId}', {
        path: {
          applicationId: to.params.app_id,
        },
      })

      if (data?.auth_strategy?.credential_type as unknown !== CREDENTIAL_TYPE.KEY_AUTH) {
        return abortNavigation('Application which uses OIDC doesn\'t have an access to the credentials page')
      }
    } catch (e) {
      throw createError({
        statusCode: 404,
        statusMessage: $i18n.t('errors.pages.not_found'),
        fatal: true,
      })
    }
  }
})
